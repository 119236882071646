/* Estilos para o CardForm do Mercado Pago seguindo a documentação oficial */

/* Estilo para o modal de pagamento - mantém o scrolling correto */
body.payment-modal-open {
  overflow: hidden;
}

/* Base de estilo para o formulário */
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Container dos campos de input */
.container {
  height: 48px !important; /* Forçar altura fixa */
  display: block;
  border: 1px solid #4c4f61;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 16px;
  background: #1e2132;
  width: 100%;
  position: relative;
  overflow: visible !important; /* Importante: permitir que os campos sobreponham os limites se necessário */
  z-index: 5; /* Elevado z-index para prevenir que outros elementos se sobreponham */
  box-sizing: border-box;
  max-width: 100%;
}

/* Estilo direto para os inputs e selects */
#form-checkout input,
#form-checkout select {
  width: 100%;
  height: 48px;
  background: #1e2132;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  max-width: 100%;
}

/* Estilo para os labels */
.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Layout para linhas de formulário */
.row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
  max-width: 100%;
}

.col {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

/* Barra de progresso */
.progress-bar {
  width: 100%;
  height: 8px;
  margin-top: 8px;
  max-width: 100%;
  box-sizing: border-box;
}

/* Informação sobre parcela fixa */
.form-info {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.installment-info {
  font-size: 14px;
  padding: 8px 12px;
  background-color: rgba(123, 45, 242, 0.15);
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

/* Estilos para responsividade */
@media (max-width: 768px) {
  #form-checkout {
    width: 100%;
    padding: 0;
    max-width: 100%;
  }
  
  .row {
    flex-direction: column;
    gap: 8px;
  }
  
  .container,
  #form-checkout input,
  #form-checkout select,
  .mp-mercadopago-iframe-container,
  .mp-mercadopago-iframe,
  .mp-mercadopago-iframe iframe {
    height: 48px !important;
    min-height: 48px !important;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .container {
    position: relative;
    overflow: visible !important;
  }
  
  .mp-mercadopago-iframe input,
  .mp-mercadopago-iframe select {
    font-size: 14px !important;
    padding: 0 6px !important;
  }
  
  .form-label {
    font-size: 13px;
  }
}

/* Estilos para o texto de políticas */
.payment-terms-text {
  font-size: 12px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 24px;
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  word-wrap: break-word;
}

.payment-terms-text p {
  margin-bottom: 8px;
  max-width: 100%;
}

.payment-terms-text a {
  color: #8B5CF6;
  text-decoration: underline;
  cursor: pointer;
}

/* Botões */
.button-container {
  margin-top: 32px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.submit-button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #7B2DF2 0%, #D92DF2 100%);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Estilos para mensagens de erro */
.mp-error-message {
  color: #f87171;
  font-size: 12px;
  margin-top: 4px;
  display: block;
  max-width: 100%;
  word-break: break-word;
}

/* Ajustes específicos para o Mercado Pago */
.mp-hidden {
  display: none !important;
}

/* Estilos para focus nos inputs */
#form-checkout input:focus,
#form-checkout select:focus {
  border-color: #8B5CF6;
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.25);
}

/* Estilo para campos com erro */
#form-checkout input.mp-input-error,
#form-checkout select.mp-input-error {
  border-color: #f87171;
}

/* Estilos para o CardForm do Mercado Pago */

/* Container principal do CardForm */
#noar-payment-form {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  pointer-events: auto !important; /* Garantir eventos de mouse */
}

/* Container dos inputs do CardForm */
.input-container {
  height: 48px;
  display: block;
  border: 1px solid #4c4f61;
  border-radius: 4px;
  padding: 0;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
  background: #1e2132;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  pointer-events: auto !important; /* Garantir eventos de mouse */
  z-index: 5; /* Aumentar z-index para garantir que esteja acima de outros elementos */
}

/* Estilos para os inputs do CardForm */
#ultra-payment-form label,
#noar-payment-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

/* Customização das cores do iframe do Mercado Pago */
.mp-mercadopago-iframe,
.mp-mercadopago-iframe-container {
  background-color: #1e2132 !important;
  color: #fff !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 48px !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  position: static !important;
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  pointer-events: auto !important; /* Garantir eventos de mouse */
  z-index: 10 !important; /* Aumentar z-index para garantir que esteja acima de outros elementos */
}

/* Garante que as dimensões do iframe sejam calculadas corretamente */
.mp-mercadopago-iframe iframe,
.mp-mercadopago-iframe-container iframe {
  width: 100% !important;
  min-height: 48px !important;
  max-width: 100% !important;
  border: none !important;
  position: static !important;
  transform: none !important;
  pointer-events: auto !important; /* Permitir eventos de mouse no iframe */
}

/* Estilização dos inputs dentro do iframe */
.mp-mercadopago-iframe input, 
.mp-mercadopago-iframe select {
  background-color: #1e2132 !important;
  color: #fff !important;
  border-color: #4c4f61 !important;
  width: 100% !important;
  height: 40px !important;
  padding: 8px 12px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  pointer-events: auto !important; /* Garantir eventos de mouse */
}

/* NOVO: Forçar interatividade para todos os inputs dentro do iframe */
iframe input,
iframe select,
iframe button {
  pointer-events: auto !important;
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

/* Garantir que o iframe não tenha overlay transparente bloqueando eventos */
.iframe-container:before,
.mp-mercadopago-iframe-container:before,
.input-container:before {
  content: none !important; /* Remover qualquer pseudo-elemento que possa estar bloqueando interações */
}

/* Estilização do texto dentro do CardForm */
.mp-mercadopago-iframe label,
.mp-mercadopago-iframe span {
  color: #fff !important;
  font-size: 14px !important;
}

/* Container do modal de pagamento */
.payment-modal-container {
  width: 100%;
  max-width: 520px;
  padding: 24px;
  box-sizing: border-box;
  background: #1e2132;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Estilos específicos para campos individuais */
#noar-payment-form__cardNumber,
#noar-payment-form__expirationDate,
#noar-payment-form__securityCode,
#noar-payment-form__cardholderName,
#noar-payment-form__issuer,
#noar-payment-form__installments {
  width: 100% !important;
  position: static !important;
}

/* Estilo para modal de pagamento do plano Plus/Basic */
.plano-plus-modal,
.plano-basic-modal {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  background: linear-gradient(135deg, #1e2132 0%, #14151f 100%);
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.plano-plus-modal-header,
.plano-basic-modal-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.plano-plus-modal-body,
.plano-basic-modal-body {
  padding: 24px;
}

/* Ajustes para visualização em telas pequenas */
@media (max-width: 480px) {
  .input-container {
    margin-bottom: 12px;
  }
  
  .mp-mercadopago-iframe input,
  .mp-mercadopago-iframe select {
    font-size: 14px !important;
  }
  
  #noar-payment-form label,
  #ultra-payment-form label {
    font-size: 13px;
  }
}

/* Correções para o iframe do Mercado Pago - Máscaras de campos */
body > div:not(.mp-mercadopago-iframe-container):not(.react-modal):not(.lightbox):not(#root) > input[type="text"],
body > div:not(.mp-mercadopago-iframe-container):not(.react-modal):not(.lightbox):not(#root) > input[type="tel"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  z-index: -1 !important;
  pointer-events: none !important;
}

/* Melhora o posicionamento dos iframes dentro dos containers */
.mp-mercadopago-iframe-container, 
.mp-mercadopago-iframe {
  position: static !important;
  transform: none !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
}

.mp-mercadopago-iframe iframe {
  position: static !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

/* Ajustes para corrigir o posicionamento em mobile */
@media (max-width: 768px) {
  /* Correções específicas para mobile */
  .mp-mercadopago-iframe-container,
  .mp-mercadopago-iframe {
    min-height: 48px !important;
  }
  
  .container {
    height: 48px !important;
  }
}

/* ======== NOVO: ESTILOS ATUALIZADOS PARA MERCADO PAGO FIELDS ======== */

/* ---- Container dos iframes ---- */
.container div[id^="form-checkout__"] {
  width: 100%;
  height: 100%;
  position: static !important;
  display: block !important;
  max-width: 100%;
  box-sizing: border-box;
}

/* ---- Configurações para os containers de iframes do MP ---- */
.mp-mercadopago-iframe-container {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
  background-color: transparent !important;
  border: none !important;
  display: block !important;
  z-index: 10 !important;
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

/* ---- Camada intermediária do iframe ---- */
.mp-mercadopago-iframe {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
  transform: none !important;
  display: block !important;
  background-color: transparent !important;
  z-index: 11 !important;
  border: none !important;
  top: 0 !important;
  left: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

/* ---- O iframe propriamente dito ---- */
.mp-mercadopago-iframe iframe {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
  display: block !important;
  transform: none !important;
  border: none !important;
  background: transparent !important;
  z-index: 12 !important;
  visibility: visible !important;
  opacity: 1 !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

/* ---- Estilo para campos mascarados do MP ---- */
.mp-mercadopago-iframe input,
.mp-mercadopago-iframe select {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  color: #ffffff !important;
  border: none !important;
  padding: 0 8px !important;
  font-size: 16px !important;
  box-sizing: border-box !important;
  outline: none !important;
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  max-width: 100% !important;
}

/* Garantir que seletores mais específicos tenham prioridade */
body .container > div[id^="form-checkout__"] .mp-mercadopago-iframe-container,
body .container > div[id^="form-checkout__"] .mp-mercadopago-iframe,
body .container > div[id^="form-checkout__"] .mp-mercadopago-iframe iframe {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  position: static !important;
  transform: none !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  overflow: visible !important;
}

/* ---- Esconder campos extras que possam aparecer fora do form ---- */
body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="tel"],
body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="text"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  width: 0 !important;
  height: 0 !important;
  z-index: -1 !important;
  pointer-events: none !important;
}

/* ---- Animação de loading durante a digitação ---- */
@keyframes mp-field-focus {
  0% { border-color: #4c4f61; }
  50% { border-color: #8B5CF6; }
  100% { border-color: #4c4f61; }
}

.container:focus-within {
  animation: mp-field-focus 2s infinite;
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.15);
}

/* ---- Ajustes para telas muito pequenas ---- */
@media (max-width: 360px) {
  .row {
    flex-direction: column;
    gap: 16px;
  }
  
  .col {
    width: 100%;
  }
  
  #form-checkout input,
  #form-checkout select,
  .container {
    font-size: 14px !important;
    padding: 0 6px !important;
  }
  
  .form-label {
    font-size: 12px;
  }
  
  .mp-mercadopago-iframe input,
  .mp-mercadopago-iframe select {
    font-size: 14px !important;
    padding: 0 6px !important;
  }
}

/* Estilos base para os campos do Mercado Pago */

/* Container do formulário */
.payment-form-container {
  max-width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  width: 100% !important;
}

/* Regra específica para div.sc-jBqsNv.liaoNU */
div.sc-jBqsNv.liaoNU {
  max-width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

/* Regra para os itens dentro do container principal */
div.sc-jBqsNv.liaoNU > * {
  max-width: 100% !important;
  box-sizing: border-box !important;
  width: 100% !important;
}

/* Estilos para os containers dos iframes */
.mp-mercadopago-iframe-container {
  position: static !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 48px !important;
  display: block !important;
  background: transparent !important;
  overflow: visible !important;
}

/* Camada intermediária */
.mp-mercadopago-iframe {
  position: static !important;
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  background: transparent !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

/* Iframe em si */
.mp-mercadopago-iframe iframe {
  position: static !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  display: block !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

/* Container principal para cada campo */
.container {
  height: 48px !important;
  max-width: 100% !important;
  width: 100% !important;
  box-sizing: border-box !important;
  overflow: visible !important;
}

/* Inputs do formulário */
input, select, textarea {
  max-width: 100% !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Formulário principal */
#form-checkout {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

/* Ajustar título do formulário */
.form-title {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  hyphens: auto !important;
}

/* Ajustar linhas e colunas */
.row {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.col {
  max-width: 100% !important;
  box-sizing: border-box !important;
  flex: 1 1 100% !important;
}

/* Esconder elementos indesejados fora do documento */
body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="tel"],
body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="text"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/* Media queries para telas menores */
@media (max-width: 768px) {
  .payment-form-container {
    padding: 15px !important;
  }
  
  .row {
    flex-direction: column !important;
  }
}

@media (max-width: 480px) {
  .container {
    height: 52px !important;
  }
}

@media (max-width: 360px) {
  .payment-form-container {
    padding: 10px !important;
  }
  
  .form-title {
    font-size: 18px !important;
  }
} 